import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [2,5];

export const dictionary = {
		"/": [~6],
		"/dashboard": [7,[2]],
		"/dashboard/[partnerID]": [~8,[2]],
		"/dashboard/[partnerID]/api-keys": [9,[2]],
		"/dashboard/[partnerID]/domains": [~10,[2,3]],
		"/dashboard/[partnerID]/domains/[domainID]": [~11,[2,3]],
		"/dashboard/[partnerID]/emails": [~12,[2,4]],
		"/dashboard/[partnerID]/logs": [13,[2]],
		"/dashboard/[partnerID]/settings": [~14,[2,5]],
		"/dashboard/[partnerID]/settings/documents": [15,[2,5]],
		"/dashboard/[partnerID]/settings/integrations": [16,[2,5]],
		"/dashboard/[partnerID]/settings/invoices": [17,[2,5]],
		"/dashboard/[partnerID]/settings/team": [18,[2,5]],
		"/login": [~19],
		"/logout": [~20],
		"/registration": [~21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';